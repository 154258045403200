import React from 'react'
import {BiError} from 'react-icons/bi'
import { Link } from "react-router-dom";
import styled from 'styled-components';
export const NotFoundPage = (props) => {
    const logo = "https://brass-lynx-1229.twil.io/assets/AnyonePray%20Logo-Dark.png";

  return (
    <Wrapper className='p-5'>
        {/* <div className="hero-banner__logo ">
            <img className="hero-banner__image m-2" src={logo} alt="React logo"  />
        </div> */}
        <h1>{props.errorMessage}</h1>
        <BiError size={400} color={"black"}/>
        <p>Return to the <Link to="/" style={{color: "#EC3310"}}>Home</Link> page</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
        font-size: 14pt;
    }
`;