import React, { useState, createContext, useEffect } from "react";
import { Fetch } from "../hooks/use-fetch-hook";

export const PrayerPartnerContext = createContext(null);

//this function handles the call logs and recordings that are displayed to prayer partner users
export const PrayerPartnerProvider = ({children}) => {
    const [callLogs, setCallLogs] = useState(null);
    const [totalLogsCount, setTotalLogsCount] = useState(0)
    const fetchCallLogs = async(page,filters) => {
        const url = `${process.env.REACT_APP_API_URL}/call_stats?page=${page}&${filters}`;
        var options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                "Accept": 'application/json',
            },
        };
        const result = await Fetch(url, options);
        setTotalLogsCount(result.data.total_count);
        setCallLogs(result.data.data);
        //return result.data;
    }
    const fetchTestimony = async(page) => {
        const url = `${process.env.REACT_APP_API_URL}/testimonies?page=${page}`;
        var options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                "Accept": 'application/json',
            },
        };
        const result = await Fetch(url, options);
        return result.data;
    }
    
    const blockCaller = async(data) => {
        const url = `${process.env.REACT_APP_API_URL}/blacklist`;
        var options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                "Accept": 'application/json',
            },
            body: JSON.stringify(data)
        };
        const result = await Fetch(url, options);
        return result;
    }

    return (
        <PrayerPartnerContext.Provider 
            value=
            {{
                fetchCallLogs, callLogs, totalLogsCount, fetchTestimony, blockCaller   
            }}
        >
            {children}
        </PrayerPartnerContext.Provider>
    );
}
