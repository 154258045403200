import React, {useContext} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../buttons/login-button";
import { LogoutButton } from "../buttons/logout-button";
import { SignupButton } from "../buttons/signup-button";
export const NavBarButtons = () => {
    const { isAuthenticated } = useAuth0();
  
    return (
      <div className="mt-5">
        {!isAuthenticated && (
          <>
            <LoginButton />

          </>
        )}
        {isAuthenticated && (
          <>
            <LogoutButton />
          </>
        )}
      </div>
    );
  };