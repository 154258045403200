import React, { useEffect, useContext, useState } from "react";
import { ProfileContext } from '../contexts/ProfileContext'
import styled from 'styled-components';

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Filter } from "../components/filter";
import { Dashboard } from "../components/dashboard";
ChartJS.register(ArcElement, Tooltip, Title, Legend, CategoryScale, LinearScale, BarElement);

export const SubscriberPage = () => {
    const { worker }  = useContext(ProfileContext);
  
    // useEffect(()=>{
    //     getOrganizationsReport(showDataOption)  
    // },[worker, showDataOption, organizationImage])
    
  return (
    <Wrapper className='pt-3'>
        <Header> Welcome {worker?.fullName}!</Header>
        <Filter page={"subscriberDashboard"} />
        <Dashboard />
    </Wrapper>
  );
};


const Wrapper = styled.div`
    width: 100%;
`;

const Header = styled.h1`
    font-weight: 1000;
    font-family: DM Serif Display;
    text-align: center;
`;
const Buttons = styled.div`
    display: flex; 
    justify-content: space-between;
`;

const RightButtons = styled.div`
  display: flex;

`;

const ActiveButton = styled.button`
  border: 0;
  border-radius: 8px;

  background-color: #FF7234;
  color: white;

  :hover {
    background-color: #EAC3B9;
    color: #FF7234;
  }

  :target {
    background-color: black;
  }
`;
const DisabledButton = styled.button`
  background-color: #C1DCDE;
  border: 0;
  background-color: #C8E4E7;
  display: block;
  border-radius: 8px;
`;

const ChurchInfo = styled.div`

`;
const Charts = styled.div`
    display: flex;
    justify-content: space-around;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
    }
`;

const DarkCard = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    background-color: #FCD5C9;
    box-shadow: 10px 10px 5px whitesmoke;
    border-radius: 30px;
    min-height: 23vh;
    text-align: center;
    
`;
const CardContainer = styled.div`
    display:flex;
    align-items: center;
  justify-content: center;
`;

const DarkCardValue = styled.div`
    color: #EC3310;
    align-items:center;
    display: block;
    font-size: 5em;
    font-weight: 800;
`;

const DarkCardLabel = styled.div`
    display-flex;
    vertical-align: bottom;
    color: #FF7234;
    text-transform: uppercase;

`;

const LightCard = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    background-color: #D9EEF0;
    box-shadow: 10px 10px 5px whitesmoke;
    border-radius: 30px;
    min-height: 23vh;
    text-align: center;
    width: 100%;
    
`;
const LightCardValue = styled.div`
    color: #178B96;
    align-items:center;
    display: block;
    font-size: 5em;
    font-weight: 800;
`;

const LightCardLabel = styled.div`
    display-flex;
    vertical-align: bottom;
    color: #2DB3C0;
    text-transform: uppercase;

`;
const ChurchInfoHeader = styled.h4`
    text-align: left;

    color: #FF7234;
    font-family: Recoleta;
    text-decoration: underline;
`;

export { ChurchInfo, ChurchInfoHeader };