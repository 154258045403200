import React,{useState} from "react";
import styled from "styled-components";

export const Pagination = ({totalCount, currentPage, setCurrentPage} ) => {
    const itemsPerPage = 10

    const handlePrevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage- 1);
        }
      };
    
      const handleNextPage = () => {
        if ((currentPage * itemsPerPage) < totalCount) {
          setCurrentPage(currentPage + 1);
        }
      };
    
  return (
    <Wrapper className="p-5">
      <button onClick={handlePrevPage} disabled={currentPage === 1} 
        style={{color: currentPage ===1 ? "#EC3310" : "#106271"}}>
        {"< < PREVIOUS"}
      </button>
      <span>Showing {currentPage ===1 ? currentPage : (currentPage*itemsPerPage)-9} to {currentPage*itemsPerPage} of {totalCount}</span>
     
      <button onClick={handleNextPage} disabled={(currentPage * itemsPerPage) >= totalCount}
      style={{color: (currentPage * itemsPerPage) >= totalCount ? "#EC3310" : "#106271"}}>
      {"NEXT > >"}
      </button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: "Futura PT";
    font-size: 16px;

    button{
        background-color: #FFF;
        border: none;
       color:  #106271
    }

`;