import React, { useState, createContext, useEffect } from "react";
import { showErrorMessage, showSuccessMessage } from "../helpers/useToast";
import { collection, addDoc, getDocs, where, query } from "firebase/firestore"; 
import {ref, getStorage, uploadBytesResumable} from 'firebase/storage';
import { db } from "../js/firebase";
import { Fetch } from "../hooks/use-fetch-hook";
import { formatDate } from "../helpers/utils";
export const OrganizationContext = createContext(null);

//the context contains values and functions regarding to the organization table in our database
export const OrganizationProvider = ({children}) => {

    const [filterByArray, setFilterByArray] = useState([])
    const [filterValueArray, setFilterValueArray] = useState([])
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [tier, setTier] = useState("");
    const [stripeId, setStripeId] = useState("");
    const [denomination, setDenomination] = useState("");
    const [paymentTerm, setPaymentTerm] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [province, setProvince] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [country, setCountry] = useState("");
    const [website, setWebsite] = useState("");
    const [organizationPhoneNumber, setOrganizationPhoneNumber] = useState("");
    const [carePersonName, setCarePersonName] = useState("");
    const [carePersonEmail, setCarePersonEmail] = useState("");
    const [carePersonPhone, setCarePersonPhone] = useState("");
    const [assignedNumber, setAssignedNumber] = useState("");
    const [prayerPartnersCount, setPrayerPartnersCount] = useState("");
    const [serviceStartTimes, setServiceStartTimes] = useState("");
    const [churchPrograms, setChurchPrograms] = useState("");
    const [monthlyOrganization, setMonthlyOrganization] = useState([]);
    const [organizations, setOrganizations ] = useState(undefined);
    const [organizationNumbers, setOrganizationNumbers ] = useState({});
    const [showAllOrganizations, setShowAllOrganizations ] = useState(false);

    const [image, setImage] = useState(null);
//run the get monthly organizations once
    useEffect(()=>{
        getMonthlyOrganization()
    }, [])

    //run every time a new filter option is selected
    useEffect(()=>{
        filterOrganizations(filterByArray, filterValueArray)
    }, [filterByArray])

    //organizations where payment term is monthly 
    const getMonthlyOrganization = async() =>{
        const monthlyOrgs = [];
        const orgRef = collection(db, "organizations");
        const q = query(orgRef, where("paymentTerm", "==", "monthly"));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            monthlyOrgs.push(doc.data());
        });
        setMonthlyOrganization(monthlyOrgs);
    }

    //filter the organizations based on the filter conditions
    const filterOrganizations = async(filterBy, filterValue) =>{
        var collections = [];
        const conditions = [];
        var organizationNumbers = [];

        filterBy.forEach((filter, index)=>{
            if(Array.isArray(filterValue[index])){
                conditions.push(where(filter, "in", filterValue[index]))
            }else{
                conditions.push(where(filter, "==", filterValue[index]))
            }
        }) 

        const orgRef = collection(db, "organizations");
        const q = query(orgRef, ...conditions);
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            collections.push(doc.data());
            organizationNumbers[doc.data().name] = doc.data().assignedNumber
        });
        setOrganizationNumbers(organizationNumbers)
        setOrganizations(collections);
    }
    
    //add organization to the table if all conditions are passed
    const addOrganization = async(setShow) => {
         if(organizations.find(organization => organization.name === name)){
            showErrorMessage(`Organization with name '`+name+`' already exists`);
            return;
        }

        if(!name || !type || !address || !city || !province || !postalCode || !country 
            || !website || !organizationPhoneNumber  || !carePersonName || !carePersonEmail 
            || !carePersonPhone || !tier || !paymentTerm || !stripeId || !denomination){
            showErrorMessage("Some fields are required to complete Sign Up ");
            return;
        }

         if(!image){
            showErrorMessage("Organization Logo is required");
            return;
         }
         const storage = getStorage();
        
        const storageRef = ref(storage, `/logos/${name}.png`)
        uploadBytesResumable(storageRef, image);
        
        
        await addDoc(collection(db, "organizations"), {
            name, type, tier, stripeId, denomination, paymentTerm, address, city, province, country,
            postalCode, website, organizationPhoneNumber, carePersonName, carePersonEmail, 
            carePersonPhone, assignedNumber, prayerPartnersCount, 
            serviceStartTimes, churchPrograms 
        });
        showSuccessMessage("New Organization Added Successfully");
        setShow(false);

    }

    //get total monthly calls for all monthl organizations
    const getTotalMonthlyCalls = async() => {
        if(monthlyOrganization){
            var firstDay = new Date(); // current date
            firstDay.setDate(1);
            firstDay.setDate(0); // set to last day of previous month
            let firstDayOfPreviousMonth = new Date(firstDay.getFullYear(), firstDay.getMonth(), 1);

            var lastDayOfPreviousMonth = new Date(); 
            lastDayOfPreviousMonth.setDate(1); // going to 1st of the month
            lastDayOfPreviousMonth.setHours(-1); // removing on hour goes to previous month
            lastDayOfPreviousMonth.setMinutes(59)
            lastDayOfPreviousMonth.setSeconds(59)

            var monthlyOrgs = []

            await Promise.all(monthlyOrganization.map(async(organization)=>{
                const url = `${process.env.REACT_APP_API_URL}/org_totals?twilio_number=
                ${encodeURIComponent(organization.assignedNumber)}&start_date=${formatDate(firstDayOfPreviousMonth)}&end_date=${formatDate(lastDayOfPreviousMonth)}`;
                var options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                        "Accept": 'application/json',
                    },
                }
                const result = await Fetch(url, options);
                monthlyOrgs.push({stripeId: organization.stripeId, tier: organization.tier, 
                    type: organization.type, totalMonthlyCalls: result.total_calls})
            }))
            return monthlyOrgs;
        }
    }

    //on logo change while creating the organization
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    }

    return (
        <OrganizationContext.Provider 
            value=
            {{
                addOrganization, assignedNumber, setAssignedNumber, prayerPartnersCount, setPrayerPartnersCount, organizations, image, tier, setTier, 
                name, setName, type, setType, address, setAddress, city, setCity, province, setProvince, postalCode, setPostalCode, country, setCountry, onImageChange,   
                website, setWebsite,  organizationPhoneNumber, setOrganizationPhoneNumber, carePersonName, setCarePersonName, paymentTerm, setPaymentTerm, 
                carePersonEmail, setCarePersonEmail, carePersonPhone, setCarePersonPhone, serviceStartTimes, setServiceStartTimes, churchPrograms, setChurchPrograms,
                filterByArray, setFilterByArray, filterValueArray, setFilterValueArray, organizationNumbers, filterOrganizations, denomination, setDenomination, stripeId,
                setStripeId, showAllOrganizations, setShowAllOrganizations, monthlyOrganization, getTotalMonthlyCalls
            }}
        >
            {children}
        </OrganizationContext.Provider>
    );
}
