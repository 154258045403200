import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "./login-button";

// this component handles the logout authorization of a signed up user in our Auth0 tenant

export const LogoutButton = () => {
  const { logout } = useAuth0();

    const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Button className="p-3" onClick={handleLogout}>
     LOG OUT
    </Button>
  );
};