import "./styles/App.css"
import { useAuth0 } from "@auth0/auth0-react";

import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import {InvoicePage} from "./pages/invoice-page";
//import { ProtectedPage } from "./pages/protected-page";
//import { PublicPage } from "./pages/public-page";
import { PageFooter } from "./components/page-footer";
//import { CallbackPage } from "./pages/callback-page";
//import { PrayerPartnerPage } from "./pages/prayer-partner-page";
// import { SubscriberPage } from "./pages/subcriber-page";
// import { SuperAdminPage } from "./pages/super-admin-page";
// import { PrayerPartnerPage } from "./pages/prayer-partner-page";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { ToastContainer } from 'react-toastify';
import { ViewProfile } from "./pages/view-profile";
import { NavBar } from "./components/navigation/nav-bar";
export const App = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }
  return (
    <>
      <NavBar/>
      <Routes>

          <Route exact path="/" element={<HomePage />} />
          {/* <Route path="/" element={<SuperAdminPage />} /> */}
          {/* <Route path="/" element={<SuperAdminPage />} /> */}

          <Route
           path="/profile"
            element={<AuthenticationGuard component={ProfilePage} />}
          />
        <Route
           path="/invoices"
            element={<AuthenticationGuard component={InvoicePage} />}
          />
        <Route path="/callback" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage errorMessage="Page not Found" />} />
      </Routes>
      <PageFooter/>
      <ToastContainer />
      <ViewProfile/>
    </>
  );
}
