import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { ViewOrganizations } from '../components/view-organizations';
import { AddOrganization } from '../components/add-organization';
import CreateAccount from '../components/create-account';
import { OrganizationContext } from "../contexts/OrganizationContext";
import { ProfileContext } from '../contexts/ProfileContext';
export const AdminPage = () => {
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [showLock, setShowLock] = useState(false);

  const {showAllOrganizations, setShowAllOrganizations} = useContext(OrganizationContext);
  const {showReports, setShowReports} = useContext(ProfileContext)

  return (
    <Wrapper>
      <div className="content-layout">
        <h1 id="page-title" className="text-center content__title">
          Admin Page
        </h1>
        {!showAllOrganizations  ?
        <div className="content__body">
            <Wrapper className='row mb-5'>
                <ViewOptions>
                    <AddOrganizationButton onClick={()=> {setShow(true)}}>ADD A NEW ORGANIZATION</AddOrganizationButton>
                    <CreateAccountButton onClick={()=> setShowLock(true)}>CREATE NEW USER ACCOUNT </CreateAccountButton>
                    <ViewOrganizationsButton onClick={() => 
                    {
                      setShowAllOrganizations(true)
                      setShowReports(false)
                    }}>VIEW ALL ORGANIZATIONS</ViewOrganizationsButton>
                </ViewOptions>
            </Wrapper>
        </div>
        : <ViewOrganizations/>
        }
      </div>
      <AddOrganization setShow={setShow} show={show} onHide={()=>setShow(false)} />
      <CreateAccount showLock={showLock} setShowLock={setShowLock} />

    </Wrapper>
  );
};
const Wrapper = styled.div`
    width: 100%;
`

const Button = styled.button`
    border-radius: 8px;
    display:block;
    border: 0;
    width:30%;
    height: 120px;
    color: white;
    font-weight: 500;
`;
const ViewOptions = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 1;
    height: 85vh;
`;
const AddOrganizationButton = styled(Button)`
    background-color: #FF7234;
`;

const ViewOrganizationsButton = styled(Button)`
    background-color: #115057;
`;
const CreateAccountButton = styled(Button)`
    background-color: #40D13D;

`;