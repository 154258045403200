import React, {useContext, useEffect, useState} from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import styled from "styled-components";
import "../styles/Dashboard.css";
import { getUserInvoices } from "../services/stripe-service";
import { LoadingSpinner } from "../components/loading-spinner";
import { MdKeyboardArrowRight } from "react-icons/md";

export const InvoicePage = () => {
    const {worker, organization} = useContext(ProfileContext);
    const [invoiceStatus, setInvoiceStatus] = useState("paid");
    const [invoicesInfo, setInvoicesInfo] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleSwitch = async(e) =>{
        setInvoiceStatus(e.target.value)

    }
    useEffect(()=>{
        (async () =>{
            if(organization){
                let invoiceData = await getUserInvoices(organization.stripeId, invoiceStatus);
                setLoading(false)
                setInvoicesInfo(invoiceData)
            }
        })();
    }, [invoiceStatus,organization])
    if(loading) return <LoadingSpinner/>

    return (
    
        <Wrapper>
            <Buttons className="m-2">
                <div className="container">
                    <div className="switches-container">
                    <input type="radio" id="switchPaid" name="switchStatus" value="paid" checked={invoiceStatus === "paid"} onChange={handleSwitch} />
                    <input type="radio" id="switchUnpaid" name="switchStatus" value="open" checked={invoiceStatus === "open"} onChange={handleSwitch} />
                    <label htmlFor="switchPaid">PAID </label>
                    <label htmlFor="switchUnpaid">UNPAID </label>
                    <div className="switch-wrapper">
                        <div className="switch">
                            <div>PAID</div>
                            <div>UNPAID</div>
                        </div>
                    </div>
                    </div>
                </div>
            </Buttons>
            <div style={{display: "flex", alignItems: "center", justifyContent: 'center'}}>
            {invoicesInfo.length > 0 &&
                <InvoiceWrapper>  
                    <thead><tr><th>Invoices</th></tr></thead>
                    <tbody>{
                        invoicesInfo.map((invoice, index)=>{
                            return(
                                <InvoiceItem className="p-2 m-3 rounded" key={index} >
                                    <NumberTd>#{invoice.invoiceNumber}</NumberTd>

                                    <td style={{color: "darkgray"}}>Date Created: {new Date(invoice.createdAt * 1000).toDateString()}</td>

                                    <td style={{color: "darkgray"}}>{invoice.customerName}</td>

                                    <StatusTd> {'\u2022'}{invoice.invoiceStatus.charAt(0).toUpperCase() + invoice.invoiceStatus.slice(1)}</StatusTd>

                                    <AmountTd>${(invoice.totalAmount/100).toFixed(2)}</AmountTd>

                                    <td> <a href={invoice.url} target="_blank"><MdKeyboardArrowRight/> </a></td>
                                </InvoiceItem>
                            )  
                        })
                    }
                    </tbody>
                </InvoiceWrapper>
                }
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`

`;

const InvoiceWrapper = styled.table`
    width: 80%;
`;
const InvoiceItem = styled.tr`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F5F5F5;
    td{
         padding: 1em;
       
        a{
            color: #FF7234;
            svg{
                font-size: 1.8em;
            }
        }
       
    }
   
`;
const Buttons = styled.div`
    width: 350px;
    font-size: 10pt;
`;

const AmountTd = styled.td`
    font-weight: 700;
    font-size: 1.4em;
    display: flex;
    width: 120px;
    
`;

const NumberTd = styled.td`
    font-weight: 500;
`;

const StatusTd = styled.td`
    color: #2DB3C0;
    width: 80px;
    text-align: center;
    font-weight: 450;
`;