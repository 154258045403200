import React from "react";
import { NavBarButtons } from "../components/navigation/nav-bar-buttons";
import styled from "styled-components";
export const HomePage = () => {

    return(
    <Wrapper>
        <Hero className="pt-5 px-5 row">
            <HeroImage className="px-4 col-md-7 col-sm-12">
                <img className="" src={require("../assets/images/banner.png")} alt="A lady Praying"/>
            </HeroImage>
            <HeroText className="text-center col-md-5 col-sm-12">
                <h1>Welcome to the Anyone Pray Dashboard</h1>
                <p style={{color: "#2DB3C0", fontWeight: "500" }} className="mt-3 p-1">Use the dashboard to access your organization's data and resources in one place!</p>
                <NavBarButtons />
            </HeroText>
        </Hero>
        <Bottom><img src={require("../assets/images/waves.png")} alt="A lady Praying"/></Bottom>
    </Wrapper>
    );
    };

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;

const Hero = styled.div`
    display: flex;
    width: 100%;
`

const HeroImage = styled.div`
 img{
    width: 100%;
 }
`

const HeroText = styled.div`
 display:flex;
 flex-direction: column;
 align-items: center; 
 justify-content: center;

 h1{
    font-size: 48px;
    font-family:  'DM Serif Display', sans-serif;
 }

`

const Bottom = styled.div`
img{
    width: 100%;
    height: 300px;
}
 
`;