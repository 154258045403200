import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ProfileProvider } from './contexts/ProfileContext';
import { OrganizationProvider } from './contexts/OrganizationContext';
import { PrayerPartnerProvider } from './contexts/PrayerPartnerContext';
import { App } from "./App";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <OrganizationProvider>
        <BrowserRouter>    
          <Auth0ProviderWithNavigate>
            <ProfileProvider>
              <PrayerPartnerProvider>
                <App />
              </PrayerPartnerProvider>
            </ProfileProvider>
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
    </OrganizationProvider>
  </QueryClientProvider>
);


