import { monthlyPrices, monthlyPricesTest } from '../assets/data';
const stripe = require('stripe')(process.env.REACT_APP_STRIPE_API);

let PRICES = {}
// test and prod have differnt prices
if (process.env.REACT_APP_STRIPE_API.includes("test")){
  PRICES = monthlyPricesTest
} else {
  PRICES = monthlyPrices
}



// const monthlyOrgs = [
//   // {stripeId: "cus_PAXA1jIqqf86OF", tier: "enterprise", type: "charity", totalMonthCalls: 5670},
//   // { stripeId: "cus_P635hu1cVDNFnh",  tier: "moderate", type: "business", totalMonthCalls: 670 },
//   {stripeId: "cus_P63AdOhT8PpMDH",  tier: "growing-moderate", type: "church", totalMonthCalls: 70},
// ];
//const invoiceList = async (stripeId, status) =>{
export const getUserInvoices = async (stripeId, status) =>{

    let invoiceData = []
    const invoices = await stripe.invoices.list({
        customer: stripeId,
        status: status,
      });

      invoices.data.forEach((data) => {
      invoiceData.push({invoiceNumber: data.number, customerName: data.customer_name, createdAt: data.created, 
        totalAmount: data.total, invoiceStatus: data.status, url: data.hosted_invoice_url})
    });

    return invoiceData;

}


//recieves all monthly organizations along with the num of calls, 
//[{orgName, numOfMonthlyCalls, stripeId, tier }]
export  const sendMonthlyBill = async(monthlyOrgs) =>{

  monthlyOrgs.forEach(async(organization) =>{
    const organizationInvoice = await stripe.invoices.create({
      customer: organization.stripeId,
      collection_method: 'charge_automatically',
      auto_advance: true,
      automatic_tax: {enabled: true},
    });

    await stripe.invoiceItems.create({ 
      customer: organization.stripeId,
      price: PRICES[organization.tier][organization.type],
      invoice: organizationInvoice.id,
      quantity: organization.totalMonthlyCalls,
    });
  })
}