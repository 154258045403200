import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

//This are the ranges shown at th bottom of the range component
export const predefinedBottomRanges = [
    // {
    //   label: 'Today',
    //   value: [new Date(), new Date()]
    // },
    // {
    //   label: 'Yesterday',
    //   value: [addDays(new Date(), -1), addDays(new Date(), -1)]
    // },
    // {
    //   label: 'This week',
    //   value: [startOfWeek(new Date()), endOfWeek(new Date())]
    // },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()]
    },
    {
      label: 'Last 60 days',
      value: [subDays(new Date(), 59), new Date()]
    },
    // {
    //   label: 'This month',
    //   value: [startOfMonth(new Date()), new Date()]
    // },
    // {
    //   label: 'Last month',
    //   value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
    // },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
    },
    // {
    //   label: 'Last year',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)]
    // },
    // {
    //   label: 'All time',
    //   value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
    // }
  ];

//This are the types of subcribers/organization we onboard
export const organizationTypes = [
    {
        id: 1,
        label: "Crossroads",
        value: "crossroads"
    },
    {
        id: 2,
        label: "Church",
        value: "church"
    },
    {
        id: 3,
        label: "Charity",
        value: "charity",
    },
    {
        id: 4,
        label: "Education",
        value: "education",
    },
    {
        id: 5,
        label: "Business",
        value: "business",
    },

]

//This are the different organization tiers
export const organizationTiers = [
    {
        id: 1,
        label: "Growing",
        value: "growing"
    },
    {
        id: 2,
        label: "Growing-Moderate",
        value: "growing-moderate"
    },
    {
        id: 3,
        label: "Moderate",
        value: "moderate",
    },
    {
        id: 4,
        label: "Large",
        value: "large",
    },
    {
        id: 5,
        label: "Enterprise",
        value: "enterprise",
    },

]

//This are the monthly price per call  id in stripe that relates the different types of tiers and types
export const monthlyPrices = {
    enterprise: {
      church: "price_1PsSDnKVHaYmI9HnDrlc81Ql",
      education: "price_1PsVxBKVHaYmI9HnLB30vDFs",
      charity: "price_1PsSI1KVHaYmI9HnjGEVzSrx",
      business: "price_1PsW2bKVHaYmI9HnQy2NmR4d",
    },
    large: {
      church: "price_1PsVzFKVHaYmI9HnAY3CLhxx",
      education: "price_1PsW0WKVHaYmI9HnQsVWkQJF",
      charity: "price_1PsVpmKVHaYmI9HnfWWgYXfi",
      business: "price_1PsW1hKVHaYmI9HnSV8H78dG",
    },
    moderate: {
      church: "price_1PsW4VKVHaYmI9HnZmZ2Mxa9",
      education: "price_1PsW7ZKVHaYmI9HnwvW7BZ6S",
      charity: "price_1PsW5tKVHaYmI9HnTvzoxPJO",
      business: "price_1PsW8xKVHaYmI9Hn8SwZMfQC",
    },
    "growing-moderate": {
      church: "price_1PsWBqKVHaYmI9HnUXEI2NCj",
      education: "price_1PsWE0KVHaYmI9Hn78zxJflJ",
      charity: "price_1PsWF2KVHaYmI9Hngrl27QcK",
      business: "price_1PsWCsKVHaYmI9HnbC8oFaUB",
    },
    growing: {
      church: "price_1PsWIDKVHaYmI9HnZrn59VOW",
      education: "price_1PsWKSKVHaYmI9HnKJkQVyGo",
      charity: "price_1PsWLiKVHaYmI9HnYb56xzoD",
      business: "price_1PsWJ8KVHaYmI9HndA2NO53B",
    },
  };

  export const monthlyPricesTest = {
    enterprise: {
      church: "price_1PGl06KVHaYmI9HnaeWSnbcX",
      education: "price_1PGl2HKVHaYmI9HnopElJYId",
      charity: "price_1PGl1pKVHaYmI9HnQZr0qv6r",
      business: "price_1PGl1JKVHaYmI9Hn73wH2yT4",
    },
    moderate: {
      church: "price_1PJItIKVHaYmI9HnYBzyDYB4",
      education: "price_1PJIxYKVHaYmI9Hnj63fa9om",
      charity: "price_1PJIx5KVHaYmI9HnBXEMHwWo",
      business: "price_1PJIw5KVHaYmI9HnASDbuts3",
    },
  };

export const blockReasons = [
  {
    id: 2,
    delay: 24,
    reason: "Repeated callers (1 day)"
  },
  {
    id: 3,
    delay: 48,
    reason: "Pranks, Jokes, Time waster, Harmless (2 days)"
  },
  {
    id: 4,
    delay: 168,
    reason: "Sexual, Abusive, Destructive (7 days)"
  },
  {
    id: 5,
    delay: 720,
    reason: "Overly repeated callers, Harassment (30 days)"
  },
  {
    id: 6,
    delay: 864000,
    reason: "Permanent Block"
  }
]