import React, {useContext} from "react";
import { NavBarBrand } from "./nav-bar-brand";
import { useAuth0 } from "@auth0/auth0-react";
import { ProfileContext } from "../../contexts/ProfileContext";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

export const NavBar = () => {
    const logo = "https://firebasestorage.googleapis.com/v0/b/anyonepray-dashboard.appspot.com/o/logos%2FCrossroads.png?alt=media&token=530408f4-a939-4368-8ad8-e42d6d925d01";
    const {organization, organizationImage, handleShow, worker} = useContext(ProfileContext);
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const handleLogout = () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      };

  return (
    <div>
      <Wrapper>
        <NavBarBrand />
        
        {worker &&
        <LoggedInWrapper className="m-4">      
            <>
                <ViewProfile  className="dropdown">
                    <ToggleButton id="dropdown-basic">
                        <Icon src={organizationImage ? organizationImage : logo} alt="Account Image Icon"  />
                        <span className="px-2">{organization ? organization.name.toUpperCase() : "CROSSROADS"}</span>
                    </ToggleButton>
                    <DropdownMenu className="mx-2">
                        <button className="dropdown-item" onClick={() => {navigate("/profile")}}>View Reports</button>
                        {/* Only subscribers can see profile and invoices */}
                        {organization && <button className="dropdown-item" onClick={handleShow}>View Profile</button> }
                        {worker.role === "subscriber" && organization && organization.stripeId &&  <button className="dropdown-item" onClick={() => {navigate(`/invoices`)}}>View Invoices</button> } 
                        <button className="dropdown-item" onClick={handleLogout}>Log out</button>
                    </DropdownMenu>
                </ViewProfile>
            </>
        </LoggedInWrapper>
        }
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.nav`
    display: flex;
    justify-content: space-between;
`;

const Icon = styled.img`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    border: 1px solid #FF7234;
    object-fit: contain;
;`

const LoggedInWrapper = styled.div`
    display: flex;
`;

// const Logout = styled.button`
//     background-color: #FF7234;
//     border: none;
//     color: white;
//     border-radius: 20px;
// `;

const ViewProfile = styled(Dropdown)`

`;

const ToggleButton = styled(Dropdown.Toggle)`
    background-color: transparent;
    display: flex;
    align-items: center;
    border: none;
    color: black;
    &:hover{
        background-color: transparent;
        color: black;
    }
    &:focus{
        background-color: transparent !important;
        color: black;
    }
    &:active{
        background-color: transparent !important;
        color: black;
    }
`;

const DropdownMenu = styled(Dropdown.Menu)`

    background-color: transparent;
   
    button:hover{
        background-color: #86CCDA;
    }
    a:hover{
        background-color: transparent;
    }
    button {
        border-bottom: 1px solid lightgrey;
    }
    a{
        width: 100%;
        text-decoration: none;
        color: black;
    }
`;