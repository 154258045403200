import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import styled from 'styled-components';
export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

// this component handles the login authorization of a signed up user in our Auth0 tenant
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/profile",
      },
    });
  };

  return (
    <Button className="p-3" onClick={handleLogin}>
        LOG IN
    </Button>
  );
};

export const Button = styled.button`
    color: white;
    background-color: #FF7234;
    font-size: 1.2em;
    border-radius: 30px;
    border: none;
    width: 350px;
    font-weight: 700;
`;