import React, { useEffect, useContext, useState } from "react";
import styled from 'styled-components';
import { OrganizationContext } from "../contexts/OrganizationContext";
import { ProfileContext } from "../contexts/ProfileContext";
import { Pagination } from "../components/pagination";
import { Dashboard } from "./dashboard";
import { MdClear } from "react-icons/md";
import { sendMonthlyBill } from "../services/stripe-service";
//the component that displays the different organizations and thier info

export const ViewOrganizations = () => {
    const {organizations, organizationNumbers, filterByArray, setFilterByArray, setFilterValueArray,
         showAllOrganizations, setShowAllOrganizations , getTotalMonthlyCalls} = useContext(OrganizationContext);
    const {setOrganizationNumber, showReports, setShowReports} = useContext(ProfileContext);
    const [nameClicked, setNameClicked] = useState("");
    
    const handleNameClick = (selectedOrganization) =>{
        setShowReports(true);
        setFilterByArray([]);
        setFilterValueArray([]);
        setNameClicked(selectedOrganization.name);
        setOrganizationNumber(selectedOrganization.assignedNumber);
    }

    useEffect(()=>{
        if(filterByArray.length>0) {
            setOrganizationNumber(Object.values(organizationNumbers));
            setNameClicked("");
        }else if(filterByArray.length === 0 && !nameClicked){
             setNameClicked("");
             setOrganizationNumber("+18662734444");
       }
    }, [organizationNumbers])

    const handleSendBill = async() =>{
        const result = await getTotalMonthlyCalls();
        sendMonthlyBill(result);
    }
    const isFirstOfMonth = () =>{    
        const date = new Date();
        const day = date.getDate();
        return !(day === 1);
    }
    return(
        <>
        {showAllOrganizations && 
        <AdminButtons>
            <MonthlyBill 
            disabled={isFirstOfMonth()} 
            className={ isFirstOfMonth() ? "disabled rounded m-3 p-2" : "rounded m-3 p-2"} onClick={()=> handleSendBill()}>Send Monthly Bill</MonthlyBill>
            <ClearSpan className="rounded m-3 p-2" onClick={()=> setShowAllOrganizations(false)}>Close <MdClear/></ClearSpan>
        </AdminButtons>
        }
         {organizations && !showReports ?
            <Table className="p-2">
                <thead>
                    <tr>
                        <th>Organization Name</th>
                        <th>Organization Type</th>
                        <th>Organization Tier</th>
                        <th>Organization Toll Number</th>
                        <th>Organization Care Person</th>
                        {/* <th>Bill Button</th> */}
                    </tr>
                </thead>
                <tbody>
                    {
                    organizations.map((organization, index) =>{
                    return (
                        <tr key={index}>
                            <NameTD onClick={()=>handleNameClick(organization)}>{organization.name}</NameTD>
                            <td>{(organization.type).charAt(0).toUpperCase() + (organization.type).slice(1)}</td>
                            <td>{(organization.tier).charAt(0).toUpperCase() + (organization.tier).slice(1)}</td>
                            <td>{organization.assignedNumber}</td>
                            <td>{organization.carePersonName}</td>
                            {/* <ButtonTD>{organization.tier === "growing-moderate" && <button className="rounded p-2" onClick={()=>handleSendBill(organization)}>Send Bill</button>}</ButtonTD> */}
                        </tr>
                    )
                    })
                    }
                </tbody>
            </Table>

            :
                <>
                    <p className="px-4 text-start w-100" style={{ color: "#FF7234"}} >
                        { filterByArray.length >0 ? 
                        <> Showing data for {Object.values(organizationNumbers).length} {Object.values(organizationNumbers).length === 1 ? "organization" : "organizations"} </> 
                        : nameClicked ? <>Showing Data for {nameClicked}</> : "Showing Data for Crossroads"}</p>
                    <Dashboard/>
                </>
            }
        </>
        
    )
}

const DashboardLinks = styled.div`
    display: flex;
    flex-direction: row;
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid #00323B;
    thead{
        flex-shrink: 0;
        border-bottom: 1px solid #00323B;
        background-color: #F1F1F1;
        color: #178B96;
        text-align: center;
    }
    tbody{
        text-align: center;
        tr{
            
        }
        td{
            padding: 0.5em;
            border-bottom: 1px solid #00323B;
            background-color: #fff;
            color: #178B96;
        }
    }

`;
const NameTD = styled.td`
    &:hover{
        text-decoration: underline;
        cursor: pointer
    }
`;  
const MonthlyBill = styled.button`
    background-color: #178B96;
    color: white;
`;
const ClearSpan = styled.span`
    background-color: #FF7234;
    color: white;
    float: right;
    svg{
        display: inline;
    }
    cursor: pointer;
`;

const AdminButtons = styled.div`
    display: flex;
    justify-content: space-between;

    .disabled{
        background-color: #F1F1F1 ;
        color: rgb(45, 179, 192, 0.4);
    }
`;