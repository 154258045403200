import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

//This component controls the behaviour of all the select HTML tags for filtering tables
export const CheckboxSelect = ({label, optionArray, selectedValue, setSelectedValue}) =>{
    return(
        <FormControl sx={{ m: 0, width: 250, p: 0 }}>
            <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
            <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={
                Array.isArray(selectedValue) ?  selectedValue :  []}
            onChange={(e)=> {
                setSelectedValue(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)
            }}
            //input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
        // MenuProps={MenuProps}
            >                    
                {
                optionArray.map((orgType)=>{
                return( <MenuItem key={orgType.id} value={orgType.value}>
                        <Checkbox checked={selectedValue.indexOf(orgType.value) > -1} />
                        <ListItemText primary={orgType.label} />
                    </MenuItem>
                )})
            }
            </Select>
        </FormControl>
    );
}