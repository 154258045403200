import React, { useContext, useState } from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import styled from 'styled-components';
import "../styles/Dashboard.css";
import { LoadingSpinner } from "../components/loading-spinner";
import { useQuery } from "@tanstack/react-query";
import { OrganizationContext } from "../contexts/OrganizationContext";

export const DashboardStats = () => {

    const {organizationNumbers} = useContext(OrganizationContext);
    const { worker, getOverallStats, showModel, dateRange, }  = useContext(ProfileContext);
    const organizationName = worker?.organizationName

    const { isPending, status,  isError, data, error } = useQuery({
        queryKey: ['overallStats', {showModel, dateRange} ],
        queryFn: () => getOverallStats(Object.values(organizationNumbers)),
        enabled: !!organizationName ,
    })

    if (isPending) return <></>;
    if (isError) return <></>;

    return (
        <DarkCard className='col-md-6 col-sm-10 mb-5 p-2'>
            <DarkCardValue>
                {(data.total_calls).toLocaleString("en-US")}
            </DarkCardValue>
            <DarkCardLabel>total calls from all organizations</DarkCardLabel>
        </DarkCard>

    );
}

const DarkCard = styled.div`
    background-color: #FCD5C9;
    box-shadow: 10px 10px 5px whitesmoke;
    border-radius: 30px;
    text-align: center;
`;
const DarkCardValue = styled.div`
    color: #EC3310;
    display: block;
    font-size: 5em;
    font-weight: 800;
`;

const DarkCardLabel = styled.div`
    vertical-align: bottom;
    color: #FF7234;
    text-transform: uppercase;

`;