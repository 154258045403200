import React,{useContext} from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BsImage } from "react-icons/bs";
import styled from "styled-components"
export const ViewProfile = () => {
    const {organization, show, handleClose, organizationImage} = useContext(ProfileContext);
    if(organization)
        return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <Modal.Title>View Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <EditMessage>*Contact your account manager to edit profile*</EditMessage>
            <ChurchImage> 
                { organizationImage ?  <img src={organizationImage} alt="logo" width={'146'}/> 
                : <BsImage size={146}/>     
                }
            </ChurchImage>

            <ChurchInfo className='p-3'>
                <ChurchInfoHeader className='p-2'>
                Organization Info
            </ChurchInfoHeader>
            <div className="row form-group mb-2 p-2">
            <div className="col">
                <label htmlFor="organizationName"><h6>Organization Name</h6></label>
                <input type="text" className="form-control" id="organizationName" value={organization.name} readOnly/>
            </div>

            <div className="col">
                <label htmlFor="organizationType"><h6>Organization Type</h6></label>
                <input type="text" className="form-control" id="organizationType" value={organization.type} readOnly/>
            </div>
            </div>
            <div className="row form-group mb-2 p-2">
            <div className="col">
                <label htmlFor="address"><h6>Address</h6></label>
                <input type="text" className="form-control" id="address" value={organization.address} readOnly/>
            </div>
            </div>
            <div className="row form-group mb-2 p-2">
            <div className="col">
                <label htmlFor="city"><h6>City</h6></label>
                <input type="text" className="form-control" id="city" value={organization.city} readOnly />
            </div>
            <div className="col">
                <label htmlFor="province"><h6>Province / State</h6></label>
                <input type="text" className="form-control" id="province" value={organization.province} readOnly/>
            </div>
            </div>
            <div className="row form-group mb-2 p-2">
            <div className="col">
                <label htmlFor="postalCode"><h6>ZIP / Postal Code</h6></label>
                <input type="text" className="form-control" id="postalCode" value={organization.postalCode} readOnly/>
            </div>
            <div className="col">
                <label htmlFor="country"><h6>Country</h6></label>
                <input type="text" className="form-control" id="country" value={organization.country} readOnly/>
            </div>
            </div>
            <div className="row form-group mb-2 p-2">
            <div className="col">
                <label htmlFor="website"><h6>Organization Website</h6></label>
                <input type="text" className="form-control" id="website" value={organization.website} readOnly/>
            </div>

            <div className="col">
                <label htmlFor="organizationPhoneNumber"><h6>Organization Phone Number</h6></label>
                <input type="text" className="form-control" id="organizationPhoneNumber"  value={organization.organizationPhoneNumber} readOnly/>
            </div>
            </div>
        </ChurchInfo>

            <PersonInfo className='p-3'>
                <PersonInfoHeader className='p-2'>
                    Person Info
                </PersonInfoHeader>              
                <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="carePersonName"><h6>Care Person Full Name</h6></label>
                        <input type="text" className="form-control" id="carePersonName" value={organization.carePersonName} readOnly />
                    </div>
                </div>
                <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="carePersonEmail"><h6>Care Person Email</h6></label>
                        <input type="email" className="form-control" id="carePersonEmail" value={organization.carePersonEmail} readOnly />
                    </div>
                </div>
                <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="carePersonPhone"><h6>Care Person Phone EXT</h6></label>
                        <input type="text" className="form-control" id="carePersonPhone" value={organization.carePersonPhone} readOnly />
                    </div>
                </div>
            </PersonInfo>

            <MoreInfo className='p-3'>
                <MoreInfoHeader className='p-2'>
                    More Info
                </MoreInfoHeader>  
                <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="assignedNumber"><h6>Assigned Number</h6></label>
                        <input type="text" className="form-control" id="assignedNumber" value={organization.assignedNumber} readOnly/>
                    </div>
                    <div className="col">
                        <label htmlFor="prayerPartnersCount"><h6>Prayer Partners Count</h6></label>
                        <input type="text" className="form-control" id="prayerPartnersCount" value={organization.prayerPartnersCount} readOnly />
                    </div>
                </div>
                <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="serviceStartTimes"><h6>Sunday Service Start Times</h6></label>
                        <input type="text" className="form-control" id="serviceStartTimes" value={organization.serviceStartTimes}  readOnly />
                    </div>
                </div>
                <div className="row form-group mb-2 p-2">
                    <div className="col">
                        <label htmlFor="churchPrograms"><h6>Church Programs</h6></label>
                        <input type="text" className="form-control" id="churchPrograms" value={organization.churchPrograms} readOnly />
                    </div>
                </div>
            </MoreInfo>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


const ChurchImage = styled.div`
       
    display: flex;
    justify-content: center;
`;

const ChurchInfo = styled.div`

`;

const PersonInfo = styled.div`
    background-color: #f3f9fa;
    input{
        background-color: #f3f9fa;
    }
`;

const PersonInfoHeader = styled.h4`
  text-align: left;
    color: #2DB3C0;
    font-family: Recoleta;
    text-decoration: underline;
`;


const EditMessage = styled.p`
    text-align: center;
    color: #2DB3C0;
    font-weight: 300;

`
const ChurchInfoHeader = styled.h4`
    text-align: left;

    color: #FF7234;
    font-family: Recoleta;
    text-decoration: underline;
`;

const MoreInfo = styled.div`
    background-color: #FFEAE4;
    input{
        background-color: #FFEAE4;
    }
`;

const MoreInfoHeader = styled.h4`
    text-align: left;
    color: #EC3310;
    font-family: Recoleta;
    text-decoration: underline;
`;
