import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { PrayerPartnerContext } from "../contexts/PrayerPartnerContext";
import { ProfileContext } from "../contexts/ProfileContext";
import { Pagination } from "../components/pagination";
import { Dashboard } from "../components/dashboard";
import { Filter } from "../components/filter";
import { Testimony } from "../components/testimony";
import { BlockCaller } from "../components/block-caller";
import moment from 'moment-timezone';

export const PrayerPartnerPage = () => {

    const {callLogs, fetchCallLogs, totalLogsCount} = useContext(PrayerPartnerContext);
    const {report, loading} = useContext(ProfileContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterString, setFilterString] = useState("");
    const [showLogs, setShowLogs] = useState(true);
    const [activeSection, setActiveSection] = useState("logs"); // Default to 'logs' section


    useEffect(()=>{
        fetchCallLogs(currentPage,filterString);
        window.scrollTo({top: 0, behavior: "smooth"});
    },[currentPage,filterString])
    return(
        <Wrapper className="p-2">
            <h1>Prayer Partner Dashboard</h1>
            <DashboardLinks className="m-3">
                <DashboardButton className="px-4 p-2 m-2" style={{backgroundColor: activeSection === "logs" ? "#2DB3C0": "#F1F1F1", color: activeSection === "logs" ? "#FFF" : "#86CCDA"}} onClick={() => setActiveSection("logs")}>CALL LOGS</DashboardButton>
                <DashboardButton className="px-4 p-2 m-2" style={{backgroundColor: activeSection === "testimony" ? "#2DB3C0": "#F1F1F1", color: activeSection === "testimony" ? "#FFF" : "#86CCDA"}} onClick={() => setActiveSection("testimony")}>TESTIMONIALS</DashboardButton>
                <DashboardButton className="px-4 p-2 m-2" style={{backgroundColor: activeSection === "reports" ? "#2DB3C0": "#F1F1F1", color: activeSection === "reports" ? "#FFF" : "#86CCDA"}} onClick={() => setActiveSection("reports")}>REPORTS</DashboardButton>
                <DashboardButton className="px-4 p-2 m-2" style={{backgroundColor: activeSection === "block" ? "#2DB3C0": "#F1F1F1", color: activeSection === "block" ? "#FFF" : "#86CCDA"}} onClick={() => setActiveSection("block")}>BLOCK </DashboardButton>


                {/* <DashboardButton className="px-4 p-2 m-2" style={{backgroundColor: showLogs ? "#2DB3C0": "#F1F1F1", color: "#86CCDA"}} onClick={() => setShowLogs(false)}> CONTACT US</DashboardButton> */}
            </DashboardLinks>
            {
                activeSection === "logs" && 
                <>
                    <Filter setFilterString={setFilterString} page={"callLogs"}/>
                    {callLogs &&
                        <Table className="p-4">
                            <thead>
                                <tr>
                                    <th>Call Date & Time</th>
                                    <th>Agent's Name</th>
                                    <th>Caller Number</th>
                                    <th>Organization Number</th>
                                    <th>Recording</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    callLogs.map((callLog) =>{ 
                                        return (
                                            
                                            <tr key={callLog.task_sid}>
                                                <td>
                                                    {/* Convert the datetime from utc to est using moment */}
                                                    {moment.utc(callLog.date).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')}
                                                
                                                </td>
                                                <td>{callLog.agent_full_name}</td>
                                                <td>{callLog.user_number}</td>
                                                <td>{callLog.twilio_number}</td>
                                                <td>
                                                    <audio controls className="m-0 p-0">
                                                        <source src={callLog.recording} type="audio/ogg"/>
                                                        <source src={callLog.recording} type="audio/mpeg"/>
                                                        Your browser does not support the audio element.
                                                    </audio>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                    <Pagination totalCount={totalLogsCount} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </>
            }     
            { activeSection === "reports" && 
            <>
                <Filter page={"subscriberDashboard"} />
                <Dashboard/>
            </>
            }  
            {/* Display the testimonials table */}
            { activeSection === "testimony" &&  
                <Testimony />
            }
             
            {/* Display the block caller form */}
            { activeSection === "block" &&  
                <BlockCaller />
            }


        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
        color: #00323B;
    }
    
`;

const DashboardLinks = styled.div`
    display: flex;
    flex-direction: row;
`;

const DashboardButton = styled.button`
    text-decoration: none;
    border-radius: 30px;
`;

const Table = styled.table`
    width: 100%;
    border: 1px solid #00323B;
    thead{
        flex-shrink: 0;
        border-bottom: 1px solid #00323B;
        background-color: #F1F1F1;
        color: #178B96;
        text-align: center;
    }
    tbody{
        text-align: center;
        tr{
            text-align: center;
        }
        td{
            padding: 0.5em;
            border-bottom: 1px solid #00323B;
            background-color: #fff;
            color: #178B96;
        }
    }

`;