import React from "react";
import { NavLink } from "react-router-dom";

export const NavBarBrand = () => {
    const logo = "https://brass-lynx-1229.twil.io/assets/A1P_Logo_ByCCCI-Dark.png";

  return (
    <div className="">
      <NavLink to="/">
        <img
          className="mt-4 mx-5 mb-1"
          src={logo}
          alt="Anyone Pray Light logo"
          width="200"
        />
      </NavLink>
    </div>
  );
};