import React from 'react';

export const LoadingSpinner = () => {

    return(
        <div className="d-flex justify-content-center" style={{width: "100vw", height: "100vh"}} >
            <div className="spinner-border" role="status" style={{width: "6em", height: "6em",  animationDuration: "2.5s"}}>
                <span className="sr-only"></span>
            </div>
        </div>
    )
}
