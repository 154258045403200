import React, { useEffect, useContext, useState } from "react";
import { ProfileContext } from '../contexts/ProfileContext'
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { OrganizationContext } from "../contexts/OrganizationContext";
import { Pagination } from "../components/pagination";
import { Filter } from "../components/filter";
import { Dashboard } from "../components/dashboard";
import { ViewOrganizations } from "../components/view-organizations";
export const SuperAdminPage = () => {

    const {showReports, setShowReports} = useContext(ProfileContext)
   

   

    return(
        <Wrapper className="p-2 pt-0">
            <h1>Super Admin Dashboard</h1>
            <DashboardLinks className="m-4">
                <Button className="px-4 p-2 m-2" style={{backgroundColor: showReports ? "#2DB3C0": "#F1F1F1", color: showReports ? "#FFF" : "#86CCDA"}} onClick={() => setShowReports(true)}>REPORTS</Button>
                <Button className="px-4 p-2 m-2" style={{backgroundColor: showReports ? "#F1F1F1" : "#2DB3C0", color: showReports ?"#86CCDA": "#FFF"}}onClick={()=> setShowReports(false)}>ORGANIZATIONS</Button>
            </DashboardLinks>
            <Filter page={"superAdminDashboard"} />
            <ViewOrganizations/>
            {/* <Pagination totalCount={totalLogsCount} currentPage={currentPage} setCurrentPage={setCurrentPage} /> */}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
        color: #00323B;
    }
    
`;

const DashboardLinks = styled.div`
    display: flex;
    flex-direction: row;
`;

const Button = styled(Link)`
    text-decoration: none;
    border-radius: 30px;

`;

const Table = styled.table`
    width: 100%;
    border: 1px solid #00323B;
    thead{
        flex-shrink: 0;
        border-bottom: 1px solid #00323B;
        background-color: #F1F1F1;
        color: #178B96;
        text-align: center;
    }
    tbody{
        text-align: center;
        tr{
            
        }
        td{
            padding: 0.5em;
            border-bottom: 1px solid #00323B;
            background-color: #fff;
            color: #178B96;
        }
    }

`;
const NameTD = styled.td`
    &:hover{
        text-decoration: underline;
        cursor: pointer
    }
`;  

const ButtonTD = styled.td`
   button{
    background-color: #F1F1F1;
    
   }
`;  