import React, { useContext, useEffect } from 'react'
import styled from 'styled-components';
import { Auth0Lock } from 'auth0-lock';
import { OrganizationContext } from '../contexts/OrganizationContext';

//This CreateAccount MODAL controls the signing up of anew user account/ subcriber /care person in AUTH0

export default function CreateAccount(props) {
    useEffect(()=>{
        if(props.showLock){
            handleSignUp();
            props.setShowLock(false);
        }
    },[props.showLock])
    const {organizations} = useContext(OrganizationContext);
    const handleSignUp = () =>{
        // e.preventDefault();
        lock.show();
    }

    const convertStringToCamelCase = (sentence) => {
        return sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g,
        function(camelCaseMatch, i) {
        if (+camelCaseMatch === 0)
            return "";
        return i === 0 ? camelCaseMatch.toLowerCase() :
        camelCaseMatch.toUpperCase();
        });
    }

   var lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_DOMAIN,  {
        // languageDictionary: {
        //     emailInputPlaceholder: "Care persons email",
        //     passwordInputPlaceholder: "Care persons email",
        // },
        theme: {
            logo:"https://brass-lynx-1229.twil.io/assets/AnyonePray%20Logo-Dark.png",
            primaryColor: '#2DB3C0',
        },
        additionalSignUpFields: [
            {
                name: "fullName",
                placeholder: "Full Name",
                validator: function(fullName) {
                    return {
                    valid: fullName.length >= 4,
                    hint: "Must have 4 or more chars" // optional
                    };
                }
            },
            //if organizations is defe=ined and not empty
            {
                type: "select",
                name: "organizationName",
                placeholder: "Select Organization Name",
                options: organizations && organizations.length ? 
                    organizations.map((organization, i) => {
                        return (
                            {
                                value: organization.name ,
                                label: organization.name
                            }
                        ) 
                    })
                : [{value:"crossroads", label: "Crossroads"}]
            },
            {
                type: "select",
                name: "role",
                placeholder: "Select Account's Role",
                options: [
                    {value: "subscriber", label: "Subscriber"},
                    {value: "account_manager", label: "Account Manager"},
                    {value: "team_member", label: "Prayer Team Member"},
                    {value: "admin", label: "Admin"}
                // {value: "[administrator]", label: "Administrator"},
                ],
            },
        ],
        allowLogin: false
    });

    return (
        <></>
    )
}