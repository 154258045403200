import React, { useState, createContext, useEffect } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { getDocs, query, where, collection } from "firebase/firestore"; 
import { db } from "../js/firebase";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDate } from "../helpers/utils";
//This profile context handles the user/role signed in and thier functionalities
export const ProfileContext = createContext(null);

export const ProfileProvider = ({children}) => {
    const [worker, setWorker] = useState(null);
   // const [report, setReport] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [showModel, setShowModel] = useState("all")
    const [show, setShow] = useState(false);
    const [dateRange, setDateRange] = useState("");
    const [showReports, setShowReports] = useState(true);
    const [organization, setOrganization] = useState(null);
    const [organizationImage, setOrganizationImage] = useState(undefined);
    const [organizationNumber, setOrganizationNumber] = useState("")
    const { user, getAccessTokenSilently } = useAuth0();

    //get the user metadata from auth0
    useEffect(() => {
        const getUserMetadata = async () => {
            const domain =  process.env.REACT_APP_AUTH0_DOMAIN;
            try {
            const accessToken = await getAccessTokenSilently({
                authorizationParams: {
                audience: `https://${domain}/api/v2/`,
                scope: "read:current_user",
                },
            });
        
            const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;
        
            const metadataResponse = await fetch(userDetailsByIdUrl, {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                },
            });
        
            const { user_metadata } = await metadataResponse.json();
    
            setWorker(user_metadata)
            getCurrentOrganization(user_metadata)

            } catch (e) {
                console.log(e.message);
            }
        };
        if(user)
        getUserMetadata();
        
        
    }, [getAccessTokenSilently, user?.sub]);


    const getOrganizationImage = (name) =>{
        const storage = getStorage();
        getDownloadURL(ref(storage, `logos/${name}.png`))
          .then((url) => {
            setOrganizationImage(url);
          })
          .catch((error) => {
            // Handle any errors
          });

    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const fetchReport = async() => {
        if(organizationNumber){
            //show data option will be replaced, we will use first day of current month on show current month click
            //start date and or end date will be sent to the backend
            let statsUrl = `${process.env.REACT_APP_API_URL}/org_stats?twilio_number=${encodeURIComponent(organizationNumber)}`;
            let detailsUrl = `${process.env.REACT_APP_API_URL}/org_details?twilio_number=${encodeURIComponent(organizationNumber)}`;
           
            if(showModel === "monthly"){
                var date = new Date();
                var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));     
                statsUrl += `&start_date=${firstDay}`
                detailsUrl += `&start_date=${firstDay}`;
            }  
            if(dateRange){
                statsUrl += `&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`
                detailsUrl += `&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`
            }

            var options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                    'Accept': 'application/json',
                },
            }
            try{
                const [result1, result2] = await Promise.all([
                    fetch(statsUrl, options),
                    fetch(detailsUrl, options)
                ]);
    
                // Check if both responses are okay
                if (!result1.ok || !result2.ok) {
                    throw new Error('One or both API requests failed');
                }
                // Parse both results
                const stats = await result1.json();
                const details = await result2.json();
    
                // Combine the results into a reports variable
                const reports = {
                    stats,
                    details
                };
    
                return reports;
            }  catch (error) {
                console.error('Error fetching reports:', error);
                return null;
            }
        }
    }

    async function getOverallStats(organizationNumbers) {
        let totalsUrl = `${process.env.REACT_APP_API_URL}/org_totals?twilio_number=${encodeURIComponent(organizationNumbers)}`;      
        
        if(showModel === "monthly"){
            var date = new Date();
            var firstDay = formatDate(new Date(date.getFullYear(), date.getMonth(), 1));     
            totalsUrl += `&start_date=${firstDay}`
        }  
        if(dateRange){
            totalsUrl += `&start_date=${formatDate(dateRange[0])}&end_date=${formatDate(dateRange[1])}`
        }

        var options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
                'Accept': 'application/json',
            },
        }
        try{
            const response = await fetch(totalsUrl, options)

            const result = await response.json();
            return result;
        }  catch (error) {
            console.error('Error fetching reports:', error);
            return null;
        } 
        
    }

    async function getOrganizationsReport () {
        //let organizationNumber = ""
        if (worker && worker.role === "subscriber") { 
            let foundOrganization = await getCurrentOrganization(worker);
            if (foundOrganization){
                let fetchNumber = foundOrganization.assignedNumber
                setOrganizationNumber(fetchNumber)   
                //organizationNumber = fetchNumber 
            }
        } 
        //by default fetch number for the admin page is +18662734444
        else if(worker && worker.role === "super_admin"){
           // organizationNumber = organizationNumber? organizationNumber : "+18662734444"
            setOrganizationNumber(organizationNumber? organizationNumber :"+18662734444")
        }
        else if (worker && worker.role === "prayer_centre"){
            //organizationNumber = +18662734444
            setOrganizationNumber("+18662734444");
        }

        //setLoading(true);
       return fetchReport() 
    }
// get the signed in users organization
    const getCurrentOrganization = async (worker) =>{
        let foundOrganization = undefined;
        if(worker.organizationName){
            const q = query(collection(db, "organizations"), where("name", "==", worker.organizationName));
            const querySnapshot = await getDocs(q);
            let firstFlag = true;
            querySnapshot.forEach((doc) => {
                if(firstFlag){
                    firstFlag = false;
                    foundOrganization = doc.data();
                    setOrganization(foundOrganization)
                }
            });
        }
        getOrganizationImage(foundOrganization.name);
       return foundOrganization 
    }

    const formatData = (report) => {
        var labels = [];
        var values = [];
        report.forEach((obj)=> {
            labels.push(Object.values(obj)[0]);
            values.push(Object.values(obj)[1])
        })
        return [labels, values]
    }
    
    const chartReportData = (report, label, isformatted) => {        
        var labels, values;
        if(!isformatted){
            [labels, values] = formatData(report)
        }else{
            [labels, values] = report
        }
        if(label == "Age Range of Callers"){
            var adultIndex = labels.indexOf("Adult");
            labels[adultIndex] = 'Adult (26-40)'

            var middleIndex = labels.indexOf("Middle Aged");
            labels[middleIndex] = 'Middle Aged (41-58)'

            var seniorsIndex = labels.indexOf("Seniors");
            labels[seniorsIndex] = 'Seniors (59+)'

            var youngIndex = labels.indexOf("Young Adult");
            labels[youngIndex] = 'Young Adult (20-25)'

            var teenIndex = labels.indexOf("Teenager");
            labels[teenIndex] = 'Teenager (13-19)'

            var childIndex = labels.indexOf("Child");
            labels[childIndex] = 'Child (< 11 yrs)'           
        }
        return {
            labels: labels,
            datasets: [
            {
                label: label,
                data: values,
                backgroundColor: [
                '#FF7234',
                '#EC3310',
                '#2DB3C0',
                '#ADCFD4',
                '#DE735E',
                '#00323B',
                '#FFB0A2',
                '#8DDCE8',
                '#048097',
                '#77B3B8',
                '#BFE9EE',
                '#E6FDFF',
                '#FAC6B1',
                '#FFEEE9',
                '#FCD5C9',
                '#F5F5F5',
                ],
                hoverOffset: 4
            },
            ],
        }
    };
   
    //format the array for it to be displayed in the chart correctly
    // const formatArray = (report, label) => {
    //     if(report){
    //         const labelCount = {};
    //         report.forEach(element => {
    //             if(element){
    //                 const labels = element.split(',').map(label => label.split('-')[0].trim());
    //                 // Use a Set to track unique labels in this item
    //                 const uniqueLabels = new Set();

    //                 labels.forEach(label => {
    //                     if (label) {
    //                         uniqueLabels.add(label);
    //                     }
    //                 });
    //                 uniqueLabels.forEach(label => {
    //                     // Increment count for each base label
    //                     labelCount[label] = (labelCount[label] || 0) + 1;
    //                 });
    //             }
    //         });
          
    //         var callTypeLabels = Object.keys(labelCount)
    //         var callTypeValues = Object.values(labelCount)

    //         return [callTypeLabels, callTypeValues ]
    //     }
    // }

    const formatObj = (object, label) =>{
        return Object.entries(object).map(([key, value]) => ({
            [label]: key,
            total: value
        }))
    }
    const countTypesBreakdowns = (array, baseLabel) => {
        const breakdownCount = {};
        const filteredArray = array.filter(item => item.includes(baseLabel));
      
        filteredArray.forEach(item => {
            // Split the item into individual labels
            if(item){
                item.split(',').forEach(label => {
                    const [labelBase, breakdown] = label.split('-').map(part => part.trim());
        
                    if (labelBase === baseLabel && breakdown) {
                        breakdownCount[breakdown] = (breakdownCount[breakdown] || 0) + 1;
                    }
                });
            }
        });
        var breakdownLabel = Object.keys(breakdownCount)
        var breakdownValues = Object.values(breakdownCount)
        return [breakdownLabel, breakdownValues];
    };

    const evenlyDistribute = (dataset, total_calls) =>{
        //get dataset total
        let datasetTotal = 0;
        dataset.forEach((item) =>{
            datasetTotal += item.total
        })
        //get the number of missingCalls 
        let missingCalls = total_calls - datasetTotal
        if (missingCalls >= 0){
            let updatedDisposition = dataset.map((item) =>{
                let proportionDispotion = Math.round(item.total / datasetTotal) * missingCalls
                item.total += proportionDispotion;
                return item
            })
            return updatedDisposition; 
        }
        return dataset;
    }

    const breakedownReports = (reportObj, categories) =>{
        // Initialize the result object with keys for each prefix and 'otherReports'
        const groupedReports = {};
        const otherReport = {};

        // Initialize the categories in the groupedReports
        categories.forEach(category => {
            groupedReports[category] = {};
        });

        // Iterate through each entry in the report
        for (const [key, value] of Object.entries(reportObj)) {
            let addedToGroup = false;

            // Check if the key starts with one of the base categories
            categories.forEach(category => {
            if (key.startsWith(category) && key !== category) {
                const subcategory = key.replace(`${category} -`, '').trim();
                groupedReports[category][subcategory] = value;
                addedToGroup = true;
            }
            });

            // If the key doesn't start with any of the categories, add it to otherReport
            if (!addedToGroup && categories.includes(key)) {
                otherReport[key] = value;
            }
            else if (!categories.some(category => key.startsWith(category))) {
                otherReport[key] = value;
            }
        }
        return { groupedReports, otherReport };
    }

    const generateReportsCharts = (report, total_calls) =>{
        if (report) {
            var callType = breakedownReports(report.call_type_report, ["Financial Needs", "Family Concerns", "Healing", "Emotional Needs"])
            console.log(callType)
            var genderData = chartReportData(report.gender_report, 'Gender of Callers'); 
            var ageRangeData = chartReportData(report.age_range_report, 'Age Range of Callers' ); 
            var dispositionData = chartReportData(evenlyDistribute(report.disposition_report, total_calls ), 'Disposition Report');
            var callTypeData = chartReportData(formatObj(callType.otherReport, 'call_type'), "Type Of Calls");

            var finalcialNeedsData = chartReportData(formatObj(callType.groupedReports["Financial Needs"], "Financial Needs"), "Financial Needs Breakdown",)
            var healingsData = chartReportData(formatObj(callType.groupedReports["Healing"], "Healing"), "Healing Breakdown")
            var familyConcernsData = chartReportData(formatObj(callType.groupedReports["Family Concerns"], "Family Concerns"), "Family Concerns Breakdown")
            var emotionalNeedsData = chartReportData(formatObj(callType.groupedReports["Emotional Needs"], "Emotional Needs"), "Emotional Needs Breakdown")
            
            var callTypeBreakdownData = [
                finalcialNeedsData, healingsData, familyConcernsData, emotionalNeedsData
            ]
            
           // var followUpTypeData = formatArray(report[0].follow_up_type_report[0], "Type Of Follow Up");
    
            var digitalResourcesData = chartReportData(formatObj(report.digital_resource_report, 'resource'),  'Digital Resource Report');
            var physicalResourcesData = chartReportData(formatObj(report.physical_resource_report, 'resource'), 'Physical Resource Report');
           
        }
        return [dispositionData, callTypeData, callTypeBreakdownData, ageRangeData, genderData, digitalResourcesData, physicalResourcesData]
    }
    // if (report) {
        
    //     var genderData = chartReportData(report[0].gender_report, 'Gender of Callers'); 
    //     var ageRangeData = chartReportData(report[0].age_range_report, 'Age Range of Callers' ); 
    //     var dispositionData = chartReportData(evenlyDistribute(report[0].disposition_report), 'Disposition Report');
    //     var callTypeData = chartReportData(formatArray(report[0].call_type_report), "Type Of Calls",true);
    //     var finalcialNeedsData = chartReportData(countTypesBreakdowns(report[0].call_type_report, "Financial Needs"), "Financial Needs Breakdown", true)
    //     var healingsData = chartReportData(countTypesBreakdowns(report[0].call_type_report, "Healing"), "Healing Breakdown", true)
    //     var familyConcernsData = chartReportData(countTypesBreakdowns(report[0].call_type_report, "Family Concerns"), "Family Concerns Breakdown", true)
    //     var emotionalNeedsData = chartReportData(countTypesBreakdowns(report[0].call_type_report, "Emotional Needs"), "Emotional Needs Breakdown", true)
        
    //     var breakdownData = [
    //         finalcialNeedsData, healingsData, familyConcernsData, emotionalNeedsData
    //     ]
        
    //    // var followUpTypeData = formatArray(report[0].follow_up_type_report[0], "Type Of Follow Up");

    //     var digitalResourcesData = chartReportData(formatArray(report[0].digital_resource_report),  'Digital Resource Report', true);
    //     var physicalResourcesData = chartReportData(formatArray(report[0].physical_resource_report), 'Physical Resource Report', true);
       
    // }
    return (
        <ProfileContext.Provider 
            value=
            {{
                setWorker, worker, getOrganizationsReport, show, handleClose, handleShow, showModel, setShowModel,
                organizationImage, loading, organization, setDateRange, dateRange, showReports, setShowReports,
                evenlyDistribute, generateReportsCharts, organizationNumber, setOrganizationNumber, getOverallStats
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
}
