import React, {useContext} from "react";
import { ProfileContext } from "../contexts/ProfileContext";
import { AdminPage } from "./admin-page";
import { NotFoundPage } from "./not-found-page";
import { SubscriberPage } from "./subcriber-page";
import { SuperAdminPage } from "./super-admin-page";
import { PrayerPartnerPage } from "./prayer-partner-page";
import { LoadingSpinner } from "../components/loading-spinner";
export const ProfilePage = () => {
    const {worker} = useContext(ProfileContext)

  return (
    !worker ? <LoadingSpinner/> : worker.role == "subscriber" ? <SubscriberPage /> : 
    worker.role == "admin" ? <AdminPage/> : worker.role == "prayer_centre" ?  <PrayerPartnerPage /> 
    : worker.role =="super_admin" ? <SuperAdminPage/> : <NotFoundPage errorMessage="No Page found for current user" />
  );
};